.background {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(128, 128, 128, 0.267);
    z-index: 3;
}

.popup {
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: white;
    border-radius: 15px;
    padding: 40px;
    gap: 20px;
    z-index: 2;
}

.popup .header {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;
    width: 100%;
}

.popup .header p {
    flex: 1;
    text-wrap: nowrap;
    text-align: center;
    padding: 10px 20px;
    border-radius: 5px;
    border: 1px solid var(--border);
    transition: .2s;
}

.popup .header .back-x {
    flex: 0;
    width: fit-content;
}

.popup .header p.active {
    background-color: var(--border);
}


.popup .header p:not(.active):hover {
    transform: translateY(-10%);
    color: grey;
    cursor: pointer;
}

.popup .body {
    display: flex;
    flex-direction: column;
    gap: 10px;
    min-width: 400px;
    min-height: 200px;
}