body, input {
  margin: 0;
  background-color: var(--bg);
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

input {
  font-weight: 500;
}

input:focus {
  outline: none;
}

h1, h2, h3, h4, h5, h6, p {
  margin: 0;
  padding: 0;
  line-height: 1;
  color: var(--primary-text);
}

:root {
  --bg-gradient-top: #E5EED0;
  --bg-gradient-bottom: white;

  --text-p: #505B45;
  --text-s: #7C8C6B;
  --theme: #D5E7B4;
  --theme-dark: #B7C9A2;
  --theme-darker: #A2B59C;

  --primary-text: #333;
  --sub-text: #666;
  --bg: white;
  --nav: #525252;
  --border: #ededed;
  --shadow: #e2e2e2;
  --main: #ffffff;
  --sub: #ffffff;
  --accent: #ff6f61;
  --accent-hover: #ff7f73;
  --loading: #f3f3f3;
}