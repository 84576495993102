.keybinds {
    position: fixed;
    left: 20px;
    bottom: 20px;
    display: flex;
    flex-direction: column;
}

.keybind {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.keybinds .key {
    width: 30px;
}

.keybinds p {
    font-weight: 500;
    opacity: .3;
}