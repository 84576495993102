.app {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100vh;
    background: linear-gradient(to bottom, var(--bg-gradient-top), var(--bg-gradient-bottom));
}

.row {
    display: flex;
    flex-direction: row;
    gap: 10px;
    width: 100%;
}