.back {
    position: fixed;
    top: 5px;
    left: 15px;
    text-align: center;
    width: fit-content;
    font-size: 1.2;
    padding: 10px 15px;
    border-radius: 15px;
    font-weight: 500;
    transition: 0.5s;
    margin: 1vh 0;
}

.back:hover {
    cursor: pointer;
    transform: translateY(-5%);
}

.buttons {
    display: flex;
    flex-direction: row;
    align-items: center;
    border-radius: 10px;
    transition: 0.5s;
}

.buttons .gap {
    padding: 0 20px;
}

.buttons i, .timer .state i {
    border-radius: 5px;
    padding: 5px;
    font-size: 1.5em;
    color: var(--text-s);
    transition: 0.5s;
}

.buttons i:hover {
    color: var(--text-p);
    cursor: pointer;
    transform: scale(1.2);
}

.set-scramble {
    width: fit-content !important;
    padding: 0 !important;
    font-size: 2em;
    font-weight: 300;
    color: var(--text-s) !important;
}

.scramble {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    gap: 10px;

    transition: 0.5s;
}

.scramble p {
    color: var(--text-p);
    max-width: 95%;
    width: 800px;
    padding: 50px 0 30px 0;
    text-align: center;
    transition: .2s;
}

.timer {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.buttons .state {
    display: flex;
    flex-direction: row;
}

.timer .display {
    color: var(--text-p);
    font-size: 7em;
    padding: 0 0 50px 0;
}

.fade {
    opacity: .1;
}