.main {
    display: flex;
    flex-direction: column;
    width: fit-content;
    transition: .5s;
}

.cal-display:hover {
    transform: scale(1.05);
}

.nav {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 400px;
    margin: 0 auto;
    gap: 20px;
    align-items: center;
}

.nav i {
    font-size: 2em;
    color: var(--text-s);
    transition: .2s;
}

.nav i:hover {
    color: var(--text-p);
    transform: scale(1.1);
}

.nav div {
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 120px;
    gap: 5px;
    padding: 15px 50px;
    border-radius: 15px;
    background-color: var(--theme);
    transition: .5s;
}

.nav div:hover {
    cursor: pointer;
    transform: translateY(-5%);
}

.nav .title {
    font-weight: 500;
    font-size: 1.2em;
    color: var(--text-p);
}

.nav .footer {
    font-weight: 500;
    font-size: 1em;
    color: var(--text-s);
}
