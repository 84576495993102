.loading {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 30px;
  }
  
  .icons {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    transform: translateY(-70%);
  }
  
  .leaves {
    z-index: 2;
    position: absolute;
    animation: wave 1s ease-in-out infinite;
    height: auto;
  }
  
  .pot {
    z-index: 1;
    position: relative;
    transform: scale(1.75) translateY(45%);
    width: 50px;
    height: auto;
  }
  
  @keyframes wave {
    0%, 100% {
      transform: translateY(10%);
    }
    50% {
      transform: translateY(0%);
    }
  }
  
  .loading p {
    font-size: 2rem;
    color: var(--text-s);
  }