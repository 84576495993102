
.card {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 80px;
  align-items: center;

  padding: 45px;
  border-radius: 30px;
}


.activity {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 4px;
  max-width: 300px;
  margin: 0 auto;
}

.text-display {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.text-display .header {
  font-weight: 500;
  font-size: 1.2em;
  transition: .5s;
  color: var(--text-s);
}

.text-display .header:hover {
  transform: translateY(-10%);
  cursor: pointer;
}

.text-display .body {
  text-wrap: nowrap;
  font-weight: bold;
  font-size: 3em;
  color: var(--text-p);
  padding: 5px 0;
}

.text-display .footer {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  font-weight: 400;
  font-size: 2em;
  color: var(--text-s);
}

.text-display .footer p {
  color: var(--text-s);
}

.helping-text {
  text-align: center;
  font-size: 1.2em;
  opacity: .4;
  margin: 5vh 0;
}

.day {
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.8rem;
  border-radius: 4px;
  cursor: pointer;
}

.day

.out-of-month {
  background-color: transparent;
}


/* Example styles for future features like activity levels */
.day[data-level="low"] {
  background-color: #d6e685; /* Light green */
}

.day[data-level="medium"] {
  background-color: #8cc665; /* Medium green */
}

.day[data-level="high"] {
  background-color: #44a340; /* Darker green */
}

.day[data-level="very-high"] {
  background-color: #1e6823; /* Dark green */
}

.past {
  background-color: var(--theme-dark); /* A lighter color for past dates */
}

.future {
  background-color: var(--theme); /* A light color for future dates */
}

.today {
  background-color: var(--theme-darker); /* Optional: Change the background color */
}