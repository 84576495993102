.account .form, .information {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 400px;
    gap: 10px;
}

.account .form input, .settings input {
    padding: 10px;
    border: 1px solid var(--border);
    border-radius: 5px;
    background-color: transparent;
    transition: 0.5s;
}

.account .form input:focus, .settings input:focus {
    border: 1px solid transparent;
    background-color: var(--border);
}

.account .form ul {
    list-style-type: square;
    margin: 0;
}

.account .form li {
    font-size: small;
    color: var(--sub-text);
}

.form li.fail {
    color: red;
}

.form li.pass {
    color: green;
}

.account button, .settings button {
    margin-top: 10px;
    padding: 10px;
    border: none;
    border-radius: 5px;
    background-color: var(--accent);
    color: white;
    font-weight: bold;
    cursor: pointer;
    transition: 0.5s;
}

.account button:hover, .settings button:hover {
    background-color: var(--accent-hover);
    transform: translateY(-10%);
}

.account .form-switch {
    text-align: center;
    padding: 10px;
    font-weight: 500;
    transition: 0.5s;
}

.account .form-switch:hover {
    color: grey;
    cursor: pointer;
    transform: translateY(-10%);
}

.account .error {
    display: flex;
    text-align: center;
    border-radius: 5px;
    border: 1px solid red;
    color: red;
    background-color: rgba(255, 0, 0, 0.05);
    font-size: small;
    padding: 10px 20px;
    margin: 10px 0;
}

.account .message {
    display: flex;
    text-align: center;
    border-radius: 5px;
    border: 1px solid grey;
    color: grey;
    background-color: rgba(0, 0, 0, 0.05);
    font-size: small;
    padding: 10px 20px;
    margin: 10px 0;
}

.information .label {
    font-weight: bold;
    font-size: small;
    color: var(--text-s);
}

.information .value {
    font-size: x-large;
    color: var(--text-p);
    margin-bottom: 20px;
}

.settings {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
}

.settings .item {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    gap: 5px;
}

.settings .item .select, .settings .item i, .settings .item p {
    flex: 1;
}

.settings .item .select {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 5px;
    align-items: center;
}

.settings p {
    font-size: large;

}

.settings i, .settings .value {
    font-size: x-large;
    width: fit-content;
    text-align: center;
}

.settings i:hover {
    cursor: pointer;
    color: grey;
}

.settings div input {
    text-align: center;
    font-size: xx-large !important;
    padding: 5px !important;
}